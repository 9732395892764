<template>
  <b-card>
    <div v-if="can_access">
      <filtres
        @can-access="authorized"
        @set-loading="setLoading"
        @search="search"
      />
      <b-overlay
        :show="loading"
      >
        <tableau
          :rows="rows"
          :total="total"
          :currentpage="currentPage"
          @set-limit="setLimit"
          @set-current-page="setCurrentPage"
        />
      </b-overlay>
    </div>
    <div v-else>
      <notAuthorized />
    </div>
  </b-card>
</template>

<script>
import { BOverlay, BCard } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import Filtres from './FactureFiltre.vue'
import Tableau from './FactureTable.vue'
import NotAuthorized from '@/views/auth/NotAuthorized.vue'

export default {
  components: {
    BCard,
    BOverlay,
    Filtres,
    Tableau,
    NotAuthorized,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      limit: 20,
      loading: false,
      rows: [],
      total: 0,
      currentPage: 1,
      filter: { },
      can_access: true,
    }
  },
  computed: {
    activeSociete() {
      return this.$store.getters['societe/getActiveSociete']
    },
  },
  watch: {
    activeSociete() {
      this.authorized(true)
    },
  },
  methods: {
    authorized(value) { this.can_access = value },
    setLoading(value) { this.loading = value },
    setLimit(value) {
      this.limit = value
      this.search()
    },
    setCurrentPage(value) {
      this.currentPage = value
      this.search()
    },
    search(payload) {
      this.loading = true
      if (payload && payload.filtre) this.filter = payload.filtre
      const params = this.filter
      params.limit = this.limit
      params.page = this.currentPage

      this.$store.dispatch('facture/fetchAll', params).then(res => {
        this.rows = res.data
        this.total = parseInt(res.total, 10)
        this.loading = false
      })
    },
  },

}
</script>
