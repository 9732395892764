<template>
  <div class="mb-2">
    <b-row>
      <b-col md="4">
        <b-form-group :label="$t('FACTURES.FILTRE.SOCIETES')">
          <v-select
            v-model="filtre.societe"
            label="label"
            :options="societes"
            multiple
          />
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group :label="$t('FACTURES.FILTRE.ETAT')">
          <v-select
            v-model="filtre.etat"
            label="label"
            :options="etat"
            multiple
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="4">
        <b-form-group>
          <label>{{ $t('FACTURES.FILTRE.DATE_DE_CREATION_DU') }}</label>
          <flat-pickr
            v-model="filtre.dateDebut"
            class="form-control"
            :config="configDate"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <label>{{ $t('FACTURES.FILTRE.DATE_DE_CREATION_AU') }}</label>
          <flat-pickr
            v-model="filtre.dateFin"
            class="form-control"
            :config="configDate"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <label>{{ $t('FACTURES.FILTRE.RECHERCHE') }}</label>
          <b-form-input
            v-model="filtre.recherche"
            type="text"
            class="d-inline-block"
            :placeholder="$t('FACTURES.FILTRE.PLACEHOLDER_RECHERCHE')"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row align-h="end">
      <b-col
        md="4"
        class="d-flex justify-content-end"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="search()"
        >
          {{ $t('FACTURES.FILTRE.RECHERCHER') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import flatPickr from 'vue-flatpickr-component'
import { French } from 'flatpickr/dist/l10n/fr'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      societes: [],
      etat: [{ label: 'IMPAYEE', value: 'impayee' }, { label: 'PAYEE', value: 'payee' }],
      filtre: {
        societe: null,
        etat: null,
        recherche: null,
        dateDebut: null,
        dateFin: null,
      },
      configDate: {
        locale: French,
        dateFormat: 'd/m/Y',
        minDate: null,
        maxDate: null,
      },
    }
  },
  computed: {
    activeSociete() {
      return this.$store.getters['societe/getActiveSociete']
    },
  },
  watch: {
    activeSociete() {
      this.resetFilter()
    },
  },
  mounted() {
    this.$store.dispatch('jwt/me')
      .then(response => {
        const droits = response.data.droits.filter(el => (el.droit === 'facture' && el.can === true))
        const societes = []
        droits.map(el => societes.push(el.societe))
        this.societes = response.data.societes.filter(soc => societes.includes(soc.idSociete)).map(el => ({
          idSociete: el.idSociete,
          label: `${(el.nom_commercial !== null) ? el.nom_commercial : el.societe}`,
        }))

        this.resetFilter()
      })
  },
  methods: {
    resetFilter() {
      this.filtre = {
        societe: null,
        etat: null,
        recherche: null,
        dateDebut: null,
        dateFin: null,
      }

      const defaultSociete = this.societes.filter(el => el.idSociete === this.activeSociete.idSociete)

      if (defaultSociete.length > 0) {
        this.filtre.societe = defaultSociete
        this.$emit('can-access', true)
        this.search()
      } else {
        this.$emit('can-access', false)
        this.$emit('set-rows', [])
      }
    },
    search() {
      this.$emit('set-loading', true)
      this.$emit('set-rows', [])

      const filtre = {}
      if (this.filtre.societe.length > 0) filtre.societe = this.filtre.societe.map(el => el.idSociete).join(',')
      if (this.filtre.etat !== null) filtre.etat = this.filtre.etat.map(el => el.value).join(',')
      if (this.filtre.recherche !== null) filtre.recherche = this.filtre.recherche
      if (this.filtre.dateDebut !== null) { filtre.date_debut = this.$luxon(this.filtre.dateDebut, { input: { format: 'dd/mm/yyyy' }, output: { format: 'yyyy-mm-dd' } }) }
      if (this.filtre.dateFin !== null) filtre.date_fin = this.$luxon(this.filtre.dateFin, {input: { format: "dd/mm/yyyy"}, output: { format: "yyyy-mm-dd" } })

      this.$emit('search', { filtre })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
