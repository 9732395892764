<<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :sort-options="{
        enabled: false,
      }"
    >
      <div
        id="emptySlot"
        slot="emptystate"
      >
        {{ $t('REQUEST.NO_DATA') }}
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'ref'">
          {{ props.row.ref }}
        </span>
        <span v-else-if="props.column.field == 'societe'">
          {{ props.row.idSociete.societe }}
        </span>
        <span v-else-if="props.column.field == 'type_piece'">
          <span>
            <b-badge :variant="getVariant(props.row.typeFacture)">
              {{ $t(`FACTURES.TYPE.${props.row.typeFacture}`) }} <span v-if="props.row.typeFacture === 'facture_periodique'">{{ formatDate(props.row.dateDebutPeriode) }} {{ formatDate(props.row.dateFinPeriode) }}</span>
            </b-badge>
          </span>

        </span>
        <span v-else-if="props.column.field == 'montant'">
          <span class="font-weight-bolder text-right">{{ getTTC(props.row) }} €</span>
        </span>

        <span v-else-if="props.column.field == 'etat'">
          <b-badge :variant="badgeVariantEtat(props.row.etat)">
            {{ $t(`FACTURES.ETAT.${props.row.etat}`) }}
          </b-badge>
        </span>

        <span v-else-if="props.column.field == 'actions'">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
            @click="downloadPDF(props.row.idFacture)"
          >
            <feather-icon icon="DownloadIcon" />
          </b-button>
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>

    <!-- pagination -->
    <div class="mt-1">
      <b-row>
        <b-col cols="6">
          <div class="d-flex align-items-center">
            <span class="text-nowrap"> {{ $t("PAGINATION.DISPLAY") }} </span>
            <v-select
              v-model="limit"
              label="limit"
              :clearable="false"
              :options="[5,10,20,50,100]"
              class="ml-1"
            />
          </div>
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="limit"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            align="right"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>

  </div>
</template>

<script>
import vSelect from 'vue-select'
import {
  BBadge, BPagination, BRow, BCol, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BBadge,
    BPagination,
    vSelect,
    VueGoodTable,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    currentpage: {
      type: Number,
      default: 1,
    },
    rows: {
      type: Array,
      default: null,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      limit: 20,
      columns: [
        {
          label: this.$i18n.t('FACTURES.TABLE.DATE'), field: 'date', type: 'date', formatFn: this.formatDate,
        },
        { label: this.$i18n.t('FACTURES.TABLE.REF'), field: 'ref' },
        { label: this.$i18n.t('FACTURES.TABLE.SOCIETE'), field: 'societe' },
        { label: this.$i18n.t('FACTURES.TABLE.TYPE_PIECE'), field: 'type_piece' },
        { label: this.$i18n.t('FACTURES.TABLE.MONTANT'), field: 'montant', type: 'number' },
        { label: this.$i18n.t('FACTURES.TABLE.ETAT'), field: 'etat' },
        { label: this.$i18n.t('FACTURES.TABLE.ACTIONS'), field: 'actions' },
      ],
      currentPage: 1,
    }
  },
  watch: {
    limit() {
      this.$emit('set-limit', this.limit)
      this.$emit('set-current-page', 1)
    },
    currentPage() {
      this.$emit('set-current-page', this.currentPage)
    },
  },
  mounted() {
    this.currentPage = this.currentpage
  },
  methods: {
    formatDate(date) {
      return this.$luxon(date, 'dd/MM/yyyy')
    },
    badgeVariantEtat(propsField) {
      switch (propsField) {
        case 'impayee':
          return 'light-danger'
        case 'payee':
          return 'light-success'
        default:
          return ''
      }
    },
    getTTC(row) {
      let montant = 0
      if (row.prix) montant = parseFloat(row.prix)
      if (row.tva) montant *= parseFloat(row.tva)

      return (montant.toFixed(2)).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ' ')
    },
    downloadPDF(id) {
      this.$store.dispatch('facture/downladPDF', { id })
        .then(data => {
          const linkSource = `data:${data.contentType};base64,${data.base64}`
          const downloadLink = document.createElement('a')
          downloadLink.href = linkSource
          downloadLink.download = data.name
          downloadLink.click()
        })
        .catch(e => {
          alert(e)
        })
    },
    getVariant(typeFacture) {
      let variant = null
      switch (typeFacture) {
        case 'facture_periodique':
          variant = 'light-info'
          break
        case 'facture':
          variant = 'light-warning'
          break

        case 'avoir':
          variant = 'light-success'
          break

        default:
          variant = 'light-danger'
          break
      }
      return variant
    },
  },
}

</script>
